body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#webpack-dev-server-client-overlay {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #797979;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #404040;
  border: 1px solid #797979;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 5px;
}

.close-sidebar .simplebar-content {
  padding: 0 5px !important;
}

.close-sidebar .MuiListItem-root.MuiListItem-padding {
  padding: 14px !important;
  justify-content: center;
  position: relative !important;
}

.close-sidebar .MuiListItem-root.MuiListItem-padding .MuiListItemText-root {
  display: none !important;
}

.close-sidebar .MuiListItem-root.MuiListItem-padding .MuiListItemIcon-root {
  margin-right: 0 !important;
  text-align: center !important;
}

.close-sidebar .MuiListItem-root.MuiListItem-padding svg.MuiBox-root {
  position: absolute;
  margin-left: 0;
  right: 0;
  max-width: 14px;
}

.close-sidebar .MuiListItem-root.MuiListItem-padding svg.MuiBox-root.iconify--ep {
  max-width: 12px !important;
}

.close-sidebar .MuiListSubheader-root {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0 !important;
  height: 30px !important;
}

.close-sidebar .MuiListItemText-root {
  display: none !important;
}

.MuiListSubheader-root {
  color: rgb(145, 158, 171) !important;
  font-weight: 600 !important;
  letter-spacing: normal !important;
}

a.MuiListItem-root.active {
  background: rgba(81, 80, 129, 0.2) !important;
}

.css-1a71era-MuiModal-root-MuiDialog-root .MuiDialog-container .MuiPaper-root {
  min-width: 45% !important;
}

@media (max-width: 600px) {
  .css-t20us3-MuiGrid-root {
    padding-right: 0px !important;
  }
}

.css-1fgbinu-MuiFormHelperText-root.Mui-error {
  margin-left: 0px !important;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 6px;
  cursor: pointer;
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #797979;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #adadad;
  border: 1px solid #797979;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #666666;
  border: 1px solid #474646;
}

.custom-scrollbar::-webkit-scrollbar-thumb:active {
  background: #adadad;
}
